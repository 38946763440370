import React from 'react';
import { Book, Story, Depoimentos, Lancamento } from './containers'
import { FullBanner } from './components/FullBanner'
const appStyles = {
  display: 'flex', 
  flexDirection: 'column'
}

function App() {
  return (
    <div style={appStyles}>
      <Book />
      <FullBanner>
        Prefácio por <span>Guilherme Benchimol</span> fundador CEO da XP Investimentos
      </FullBanner>
      <Story />
      <FullBanner>
        ELES <span>LERAM E RECOMENDAM</span>
      </FullBanner>
      <Depoimentos />
      <Lancamento />
    </div>
  );
}

export default App;
