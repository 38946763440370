import React from "react";
import { Section } from "../../components/Section";

import './index.scss'
import DnaIcon from './dna.png'
import ToolsIcon from './tools.png'
import CreativeIcon from './creative.png'

export const Story = () => {
  return (
    <Section className="story">
      <div className="story__title">
        Um guia para que você possa tirar a sua ideia de negócio do papel e
        transformá-la em realidade.
      </div>
      <div className="story__box story__box__even">
        <div className="story__box__text">
          Rebeldia, visão, proatividade, capacidade de execução e humildade são
          apenas algumas das principais características do perfil empreendedor.
        </div>
        <div className="story__box__icon">
          <img src={DnaIcon} alt="DNA" />
        </div>
      </div>
      <div className="story__box story__box">
        <div className="story__box__icon">
          <img src={CreativeIcon} alt="Criatividade" />
        </div>
        <div className="story__box__text">
          A capacidade de trazer soluções inovadoras para problemas cotidianos
          faz com que o seu trabalho seja único e essencial. Entretanto, não
          adianta ter ferramentas poderosas se você não souber o que fazer com
          elas, certo?
        </div>
      </div>
      <div className="story__box story__box__even">
        <div className="story__box__text">
          Neste guia você vai aprender de maneira descomplicada e
          prática, ferramentas poderosas para que você consiga executar suas
          ideias e construir um negócio de sucesso. É preciso trabalho duro e
          resiliência para sobrepor os desafios e seguir em frente.
        </div>
        <div className="story__box__icon">
          <img src={ToolsIcon} alt="Ferramentas" />
        </div>
      </div>
    </Section>
  );
};
