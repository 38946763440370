import React from "react";
import "./Section.scss";

export const Section = ({ children, className, ...rest }) => {
  return (
    <section className={`section ${className}`} {...rest}>
      {children}
    </section>
  );
};

export const SectionSideA = ({children, className, ...rest}) => (
  <div className={`section__column__a ${className}`} {...rest}>
    {children}
  </div>
)

export const SectionSideB = ({children,  className, ...rest}) => (
  <div className={`section__column__b ${className}`} {...rest}>
    {children}
  </div>
)