import React from "react";
import "./index.scss";

const Newsletter = () => {
  return (
    <div className="newsletter__form">
      <span className="newsletter__form__cta">
        Receba o infográfico com as 100 ferramentas para empreendedores no seu e-mail!
      </span>

      <form
        action="https://fazendosimples.us4.list-manage.com/subscribe/post?u=0bc9b6795035022d2d9c429a8&amp;id=6f9eff1ad7"
        method="post"
        className="newsletter__form__group"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        novalidate
      >
        <input
          className="newsletter__form__input"
          placeholder="Seu nome"
          type="text"
          name="FNAME"
          id="mce-FNAME"
        />

        <input
          className="newsletter__form__input"
          placeholder="Seu melhor e-mail"
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
        />
        <button className="newsletter__form__button">
          Receber no meu e-mail
        </button>

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_0bc9b6795035022d2d9c429a8_6f9eff1ad7"
            tabindex="-1"
            value=""
          />
        </div>
      </form>
    </div>
  );
};

export default Newsletter;
