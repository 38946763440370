import React from "react";
import { Section } from "../../components/Section";
import { Card } from "../../components/Card";

import LaecioAlbuquerque from "./images/laercio-albuquerque.jpeg";
import RubensMenin from "./images/rubens-menin.jpg";
import BrianRequarth from "./images/brian-requarth.jpg";
import FabioCoelho from "./images/fabio-coelho.jpeg";

const mockDepoimentos = [
  {
    image: LaecioAlbuquerque,
    author: "Laecio Albuquerque, Presidente da Cisco Brasil",
    message:
      "Como pode um livro conseguir juntar ao mesmo tempo tanta simplicidade, inspiração, leveza e praticidade de execução sobre empreendedorismo. Faça Simples é assim, um guia prático, divertido e motivador que fará suas idéias viajarem ao fantástico mundo dos grandes e bem sucedidos negócios"
  },
  {
    image: RubensMenin,
    author: "Rubens Menin Teixeira - CEO e cofundador da MRV ",
    message:
      "Neste livro o autor transmite ao leitor, de forma simples e didática, princípios básicos do empreendedorismo e de como gerenciar o próprio negócio. Uma boa leitura para aqueles que se aventuram a empreender no nosso país."
  },
  {
    image: BrianRequarth,
    author: "Founder of Viva Real & Chairman of Grupo Zap",
    message:
      "Grandes empreendedores aprendem com outros grandes empreendedores. Gus escreve um livro claro, conciso e altamente prático que ajuda os fundadores. Em Faça Simples, ele captura importantes conceitos relevantes do ecossistema local brasileiro, bem como do mercado global."
  },
  {
    image: FabioCoelho,
    author: "Fábio Coelho, Presidente do Google Brasil",
    message:
      "Esta é uma leitura obrigatória para qualquer um que tem o desejo de criar um negócio de sucesso no Brasil. O Gustavo traz toda a sua experiência e quebra cada passo de um jeito simples e interessante, mostrando como as coisas realmente funcionam na prática. Um livro cheio de insights valiosos para empreendedores."
  }
];

export const Depoimentos = () => {
  return (
    <Section>
      {mockDepoimentos.map((item, key) => (
        <Card
          message={item.message}
          image={item.image}
          author={item.author}
          key={key}
        />
      ))}
    </Section>
  );
};
