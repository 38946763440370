import React from "react";
import { Section } from "../../components/Section";
import Newsletter from "../../components/Newsletter";

import BookImage from "../../assets/book.png";

import "./index.scss";

export const Book = () => {
  return (
    <Section className="book-section">
      <div className="book-section__header">
        <img src={BookImage} alt="Faça Simples - Capa" />
        <div className="book-section__call-to-action">
          <span className="book-section__call-to-action--highlight">
            Gustavo Caetano, fundador de uma das empresas mais inovadoras do
            país,
          </span>
          autor do Best Seller Pense Simples e agora em co-autoria com Arthur Pelegrino
          , compartilha o seu guia prático e definitivo para transformar a sua
          ideia em um negócio de sucesso de maneira descomplicada.
          <Newsletter />

        </div>
      </div>
    </Section>
  );
};
