import React from "react";
import { FullBanner } from "../../components/FullBanner";

import "./index.scss";

export const Lancamento = () => {
  return (
    <>
      <FullBanner
        style={{
          height: "350px",
          flexDirection: "column",
          background: "#f7902b",
          color: "#ffffff",
          fontWeight: "500"
        }}
      >
        <a
          href="https://www.amazon.com.br/FA%C3%87A-SIMPLES-NEG%C3%93CIO-TRANSFORM%C3%81-REALIDADE/dp/8545203357/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr="
          className="buy-now"
        >
          Comprar Agora
        </a>
      </FullBanner>
    </>
  );
};
