import React from "react";
import "./index.scss";

export const Card = ({ message, author, image, ...rest }) => {
  return <div className="card" {...rest}>
    <div className="card__image">
      <img src={image} alt={author} />
    </div>
    <div className="card__message">
      {message}
    </div>
    <div className="card__author">
      {author}
    </div>
  </div>;
};

export default Card;
