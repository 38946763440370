import React from "react";
import "./index.scss";

export const FullBanner = ({ children, ...rest }) => {
  return <div className="full-banner" {...rest}>
    {children}
  </div>;
};

export default FullBanner;
